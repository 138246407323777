import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Title } from "../Core";
import { breakpoints, device } from "../../utils";

import largeLogo from "../../assets/image/logo.png";
import smallLogo from "../../assets/image/logo_small.png";

const LogoImg = styled.img`
  height: 2rem;
`;

const Picture = styled.picture`
  height: 2rem;
`;

const Logo = ({ color = "front", height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {/* <Title color={color} variant="cardLg" className="mb-0">
        MANN TECHNOLOGIES LTD.
      </Title> */}
      {/* <LogoImg src={logo} alt="logo" /> */}

      <Picture>
        <source media={device.md} srcSet={largeLogo} />
        <img src={smallLogo} alt="logo" style={{ height: "2rem" }} />
      </Picture>
    </Link>
  );
};

export default Logo;
