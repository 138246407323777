import React, { useContext } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import { Section, Button, ButtonIcon, Span, Title, Text, Box } from "../../components/Core";
import PageWrapper from "../../components/PageWrapper";
import Contact from "../../sections/landing/Contact";
import default_img from "../../assets/image/Team/default_white.png";
import team1 from "../../assets/image/Team/team1.jpg";
import team2 from "../../assets/image/Team/team2.jpg";
import team3 from "../../assets/image/Team/team3.jpg";
import team4 from "../../assets/image/Team/team4.jpg";
import team5 from "../../assets/image/Team/team5.jpeg";
import team6 from "../../assets/image/Team/team6.jpeg";
import team7 from "../../assets/image/Team/team7.jpeg";
import team8 from "../../assets/image/Team/team8.jpeg";
import team9 from "../../assets/image/Team/team9.jpeg";
import styled from "styled-components";

const Circle = styled.div`
  width: 100%;
  max-width: 100px;
  max-height: 100px;
  padding-top: 100%;
  border-radius: 50%;
  background-size: cover;
  background-positing: center;
`;

const WorkSingle = () => {
    const gContext = useContext(GlobalContext);

    return (
        <>
            <Section className="mt-lg-5">
                <Container>
                    <Row>
                        <Col lg="8">
                            <Title variant="secSm" className="my-4">
                                ABOUT{" "}
                            </Title>
                            <Text
                                variant="p"
                                css={`
                    max-width: 750px;
                  `}
                            >
                                We are a team of driven individuals with extensive experience, each in their respective area of specialty.
                                Our vision is to improve the quality of life through the further integration and improvement of innovative
                                technology in our day to day lives. Our company, located in Vancouver Canada, creates innovative software
                                solutions across a wide range of industries.
                            </Text>
                        </Col>
                    </Row>
                </Container>
                <div className="mt-5">
                    <Container>
                        <Row>
                            <Col lg="6" className="mb-4 mb-lg-0">
                                <Text className="mb-1 mb-lg-3" variant="tag">Address</Text>
                                <a className="font-weight-bold" href="https://goo.gl/maps/4PEP6GBYa2M2" target="_blank">
                                    <Span color="primary">
                                        Vancouver, BC Office<br />#203-1929 West Broadway<br />
                                        Vancouver, British Columbia<br />Canada, V6K 2W2
                                    </Span>
                                </a>
                            </Col>
                            <Col lg="6" className="mb-4 mb-lg-0">
                                <Text className="mb-1 mb-lg-3" variant="tag">Contact</Text>
                                <a href="mailto:support@manntechnologies.ca" className="font-weight-bold">
                                    <Span color="primary">support@manntechnologies.ca</Span>
                                </a> <br />
                                <a href="tel:+1-402-4983" className="font-weight-bold">
                                    <Span color="primary">+1 778-251-8770</Span>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Section>
            <Section className="pt-4 pb-0">
                <Container>
                    <Title variant="secSm" className="mb-5 pb-lg-4">
                        Our Team
                    </Title>
                    <Row>
                        <Col lg="3"></Col>
                        <Col lg="6" className="mb-5 pr-lg-5">
                            <Container>
                                <Row>
                                    <Col xs="3">
                                        <Circle style={{ backgroundImage: `url(${team4})` }}></Circle>
                                    </Col>
                                    <Col >
                                        <Text className="mb-1 mb-lg-3" variant="tag">CEO &amp; Entrepreneur</Text>
                                        <Title variant="cardBig">
                                            Yechiel Oirechman
                                        </Title>
                                        <Span color="primary">B.A. in Rabbinical Studies</Span>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg="3"></Col>
                        <Col lg="6" className="mb-5 pr-lg-5">
                            <Container>
                                <Row>
                                    <Col xs="3">
                                        <Circle style={{ backgroundImage: `url(${team1})` }}></Circle>
                                    </Col>
                                    <Col >
                                        <Text className="mb-1 mb-lg-3" variant="tag">Backend Developer &amp; Project Manager</Text>
                                        <Title variant="cardBig">
                                            Chana Oirechman
                                        </Title>
                                        <Span color="primary">B.S. in Software Engineering</Span>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg="6" className="mb-5 pl-lg-5">
                            <Container>
                                <Row>
                                    <Col xs="3">
                                        <Circle style={{ backgroundImage: `url(${team2})` }}></Circle>
                                    </Col>
                                    <Col >
                                        <Text className="mb-1 mb-lg-3" variant="tag">Frontend Developer</Text>
                                        <Title variant="cardBig">
                                            Nahum Landau
                                        </Title>
                                        <Span color="primary">B.S. in Computer Science</Span>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg="6" className="mb-5 pr-lg-5">
                            <Container>
                                <Row>
                                    <Col xs="3">
                                        <Circle style={{ backgroundImage: `url(${team3})` }}></Circle>
                                    </Col>
                                    <Col>
                                        <Text className="mb-1" variant="tag">Office Manager</Text>
                                        <Title variant="cardSm">
                                            Rachel Cohen
                                        </Title>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg="6" className="mb-5 pl-lg-5">
                            <Container>
                                <Row>
                                    <Col xs="3">
                                        <Circle style={{ backgroundImage: `url(${team7})` }}></Circle>
                                    </Col>
                                    <Col >
                                        <Text className="mb-1" variant="tag">UX/UI Designer</Text>
                                        <Title variant="cardSm">
                                            Daniel Thomas
                                        </Title>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg="6" className="mb-5 pr-lg-5">
                            <Container>
                                <Row>
                                    <Col xs="3">
                                        <Circle style={{ backgroundImage: `url(${team9})` }}></Circle>
                                    </Col>
                                    <Col >
                                        <Text className="mb-1" variant="tag">Developer</Text>
                                        <Title variant="cardSm">
                                            Lian Rian
                                        </Title>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg="6" className="mb-5 pl-lg-5">
                            <Container>
                                <Row>
                                    <Col xs="3">
                                        <Circle style={{ backgroundImage: `url(${team6})` }}></Circle>
                                    </Col>
                                    <Col >
                                        <Text className="mb-1" variant="tag">Developer</Text>
                                        <Title variant="cardSm">
                                            Yuri Grigoriy
                                        </Title>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    {/* <Row className="my-5 pr-lg-5">
                        <Col lg="4" className="mb-5 pl-lg-5">
                            <Container>
                                <Row>
                                    <Col xs="3">
                                        <Circle style={{ backgroundImage: `url(${team5})` }}></Circle>
                                    </Col>
                                    <Col >
                                        <Text className="mb-1" variant="tag">Developer</Text>
                                        <Title variant="cardSm">
                                            Alex Vladimir
                      </Title>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col lg="4" className="mb-5 pl-lg-5">
                            <Container>
                                <Row>
                                    <Col xs="3">
                                        <Circle style={{ backgroundImage: `url(${team8})` }}></Circle>
                                    </Col>
                                    <Col >
                                        <Text className="mb-1" variant="tag">UX/UI Designer</Text>
                                        <Title variant="cardSm">
                                            Eleanor Young
                      </Title>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row> */}
                </Container>
            </Section>
        </>
    );
};
export default WorkSingle;