// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-doctors-js": () => import("./../../../src/pages/portfolio-doctors.js" /* webpackChunkName: "component---src-pages-portfolio-doctors-js" */),
  "component---src-pages-portfolio-dujour-js": () => import("./../../../src/pages/portfolio-dujour.js" /* webpackChunkName: "component---src-pages-portfolio-dujour-js" */),
  "component---src-pages-portfolio-iroomquest-js": () => import("./../../../src/pages/portfolio-iroomquest.js" /* webpackChunkName: "component---src-pages-portfolio-iroomquest-js" */),
  "component---src-pages-portfolio-pause-4-purpose-js": () => import("./../../../src/pages/portfolio-pause4purpose.js" /* webpackChunkName: "component---src-pages-portfolio-pause-4-purpose-js" */),
  "component---src-pages-portfolio-teachers-js": () => import("./../../../src/pages/portfolio-teachers.js" /* webpackChunkName: "component---src-pages-portfolio-teachers-js" */),
  "component---src-pages-portfolio-yahaduttv-js": () => import("./../../../src/pages/portfolio-yahaduttv.js" /* webpackChunkName: "component---src-pages-portfolio-yahaduttv-js" */)
}

